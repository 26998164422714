import * as React from "react";
import LoaderBg from "../images/2.0/loader-bg.png";
import LoaderIcon from "../images/2.0/loader-icon.png";

const Loader = (props) => {
  return (
    <div
      className="loader"
      style={{ display: props.loaded ? "none" : "fixed" }}
    >
      <div className="loader_container">
        <img src={LoaderIcon} className="loader_icon" />
        <img src={LoaderBg} className="loader_bg" />
      </div>
    </div>
  );
};

export default Loader;
