import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/genesis.css";
import Intro from "../components/genesis/intro.js";
import Loader from "../components/loader.js";
import Icons from "../components/icons.js";
import Teaser from "../components/secondaryTeaser.js";
import Footer from "../components/footer.js";
import Join from "../components/join.js";
import MetaverseSection from "../components/metaverseSection.js";
import HomeSVG from "../images/2.0/home.svg";

const isBrowser = typeof window !== "undefined";

// markup
const MetaversePage = () => {

  const bypassTeaser = true;

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isVideoSent, setIsVideoSent] = React.useState(false);
  const [teaserSrc, setTeaserSrc] = React.useState(null);
  const [orientation, setOrientation] = React.useState("landscape");

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = React.useState(
    isBrowser ? window.innerHeight : 0
  );

  const [footerVisible, setFooterVisible] = React.useState(false);
  const [joinHeight, setJoinHeight] = React.useState(0);
  const [muted, setMuted] = React.useState(true);

  const boxRef = React.useRef(null);
  const introRef = React.useRef(null);

  const [introHeight, setIntroHeight] = React.useState(0);
  const [section1Height, setSection1Height] = React.useState(0);
  const [section2Height, setSection2Height] = React.useState(0);
  const [section3Height, setSection3Height] = React.useState(0);
  const [section4Height, setSection4Height] = React.useState(0);
  const [section5Height, setSection5Height] = React.useState(0);
  const [section6Height, setSection6Height] = React.useState(0);

  const updateWidthAndHeight = () => {
    setWidth(isBrowser ? window.innerWidth : 0);
    setHeight(isBrowser ? window.innerHeight : 0);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };
  const onLoadEnd = () => {
    setIsVideoLoaded(true);
  };
  React.useEffect(() => {
    if(introRef.current) setIntroHeight(introRef.current.clientHeight);
    setTeaserSrc(
      "https://cdn.cultandrain.com/CULTR_TRLR_METAVERSE_SD.mp4"
    );
    setScreenOrientation();
    updateWidthAndHeight();
    if (bypassTeaser) setIsVideoLoaded(true);
    if (isBrowser) window.addEventListener("resize", updateWidthAndHeight);
    if (isBrowser)
      window.addEventListener("orientationchange", setScreenOrientation);
    return () => {
      if (isBrowser) window.removeEventListener("resize", updateWidthAndHeight);
      if (isBrowser)
        window.removeEventListener("orientationchange", setScreenOrientation);
    };
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>METAVERSE | CULT&amp;RAIN™</title>
        <link rel="canonical" href="https://www.cultandrain.com/metaverse" />
        <meta
          name="description"
          content="Luxury fashion born from Web3, merging NFTs with exclusive physical redeemables."
        />
      </Helmet>
      {isBrowser && (
        <div className="genesisPage">
          <div className="wrapper">
            {!isVideoLoaded && <Loader />}
            {isVideoLoaded && (
              <div>
                <a href="/" className="homebtn"><img src={HomeSVG} /></a>
                <Icons
                  footerVisible={footerVisible}
                  mintVisible={false}
                  page="metaverse"
                />
                {!bypassTeaser && (<a
                  ref={boxRef}
                  className={
                    "boxContainer" + (muted ? "" : " boxContainerActive")
                  }
                  onClick={toggleMuted}
                >
                  <div className="box box1"></div>
                  <div className="box box2"></div>
                  <div className="box box3"></div>
                  <div className="box box4"></div>
                  <div className="box box5"></div>
                </a>
                )}
              </div>
            )}

            <div
              className="page"
            >
              {!bypassTeaser && <Teaser
                height={height}
                muted={muted}
                teaserSrc={teaserSrc}
                landing={true}
                onLoadEnd={onLoadEnd}
              />}
              {isVideoLoaded && (
                <div>
                  <div className="metaverse-experience" ref={introRef}>
                    <div className="metaverse-experience-col">
                        <div className="metaverse-experience-copy">
                          <h5>EXPERIENCE</h5>
                          <h2>CULTR WORLD</h2>
                          <h5>METAVERSE</h5>
                          
                        </div>
                    </div>
                  </div>
                  <MetaverseSection
                    id={`section1`}
                    videoSrc={`https://cdn.cultandrain.com/metaverse/Truck.mp4`}
                    start={0}
                    height={introHeight}
                    property="right"
                    copy={`
                      <h2>ABOUT</h2>
                      <p>CULTR WORLD is our own unique metaverse platform, purpose-built for social engagement plus commerce, and rich with photo realistic graphics that push the boundaries of creativity and design. CULT&amp;RAIN is taking a radical step towards the digital future, pioneering “Phygital Luxury” fashion in an immersive experience and showcasing our products in an indistinguishable layer between virtual reality and real life.</p>
                    `}
                    orientation={orientation}
                    total={setSection1Height}
                  />
                  <MetaverseSection
                    id={`section2`}
                    start={section1Height-introHeight}
                    height={height}
                    videoSrc={`https://cdn.cultandrain.com/metaverse/Avatar.mp4`}
                    property="left"
                    copy={`
                      <h2>AVATAR</h2>
                      <h5>PICK YOUR ALTER EGO</h5>
                      <p>Explore our CULTR WORLD with 4 original Avatar designs that suit you. Each unique with outfits styled from our GENESIS sneaker and DROP 001 varsity jacket collection.</p>
                      <p>Customizable mix and match functions coming soon...</p>
                    `}
                    orientation={orientation}
                    total={setSection2Height}
                  />
                  <MetaverseSection
                    id={`section3`}
                    start={section1Height + section2Height-introHeight}
                    height={height}
                    videoSrc={`https://cdn.cultandrain.com/metaverse/Lounge.mp4`}
                    property="right"
                    copy={`
                      <h2>CULTR LOUNGE</h2>
                      <h5>COMMUNITY HANG OUT</h5>
                      <p>Socialize within CULT&amp;RAIN’s virtual event space where you can chat, dance, and party together. Features a central DJ stage, live stream video screens, and intergalactic views.</p>
                    `}
                    orientation={orientation}
                    total={setSection3Height}
                  />
                  <MetaverseSection
                    id={`section4`}
                    start={section1Height + section2Height + section3Height-introHeight}
                    height={height}
                    videoSrc={`https://cdn.cultandrain.com/metaverse/PODS.mp4`}
                    property="left"
                    copy={`
                      <h2>CULTR LOUNGE</h2>
                      <h5>DISCOVER OUR GALLERIES</h5>
                      <p>View our latest collection in 360 degrees up close within our RAIN POD gallery spaces.</p>
                    `}
                    orientation={orientation}
                    total={setSection4Height}
                  />
                  <MetaverseSection
                    id={`section5`}
                    start={section1Height + section2Height + section3Height + section4Height-introHeight}
                    height={height}
                    videoSrc={`https://cdn.cultandrain.com/metaverse/Shop.mp4`}
                    property="right"
                    copy={`
                      <h2>CULTR SHOP</h2>
                      <h5>EXPLORE OUR COLLECTIONS</h5>
                      <p>The birth of the “Virtual Social Shopping Experience” – an entirely new model of luxury e-commerce. Purchase CULT&amp;RAIN’s latest physical Core Collection and our coveted Genesis and Drop 001 NFTs.</p>
                      <p>Digital wearable drops coming soon...</p>
                    `}
                    orientation={orientation}
                    total={setSection5Height}
                  />
                  <MetaverseSection
                    id={`section6`}
                    start={section1Height + section2Height + section3Height + section4Height + section5Height - introHeight}
                    height={height}
                    videoSrc={`https://cdn.cultandrain.com/pad-rotate_highLOOP.mp4`}
                    property="left"
                    copy={`
                      <h2>CULTR PAD</h2>
                      <h5>YOUR OWN SPACE STATION HOME</h5>
                      <p>Token-gated access for our holders to four customizable rooms: Mothership, DJ Studio, Theatre, and The Gallery. Upload pictures, stream videos, invite your friends, and experience the future of virtual entertainment through a gamified experience.</p>
                    `}
                    orientation={orientation}
                    total={setSection6Height}
                  />
                  <Join start={(height*6)-introHeight} height={height} total={setJoinHeight} />
                  <Footer
                    start={
                      joinHeight
                    }
                    height={height}
                    setFooterVisible={setFooterVisible}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "none" }}
        name="Join"
        method="POST"
        data-netlify="true"
      >
        <input type="email" name="email" />
      </form>
    </main>
  );
};

export default MetaversePage;
