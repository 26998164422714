import * as React from "react";
import Plx from "react-plx";

const isBrowser = typeof window !== "undefined";
// markup
const Teaser = (props) => {
  const [playing, setPlaying] = React.useState(false);
  const videoRef = React.useRef(null);
  const teaserRef = React.useRef(null);

  const updateScrollPosition = () => {
    if ((isBrowser ? window.scrollY : 800) < 200) {
      if (!playing) {
        videoRef.current.play();
        setPlaying(true);
      }
    }
    if ((isBrowser ? window.scrollY : 800) >= 200) {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  const onLoadedData = () => {
    props.onLoadEnd();
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });
  return (
    <div ref={teaserRef} className="secondary-teaser" style={{backgroundColor:'#000',zIndex: "9999" }}>
      {props.teaserSrc&&<video
        playsInline
        loop
        muted={props.muted}
        ref={videoRef}
        onLoadedData={onLoadedData}
        autoPlay
      >
        <source src={props.teaserSrc} type="video/mp4" />
      </video>}
    </div>
  );
};

export default Teaser;
