import * as React from "react";
import { isMobile } from "react-device-detect";
import Plx from "react-plx";
const isBrowser = typeof window !== "undefined";

const MetaverseSection = (props) => {
  const sectionRef = React.useRef(null);
  const videoRef = React.useRef(null);

  let observer = null;

  const [playing, setPlaying] = React.useState(false);

  let parallaxData = [
    {
      start: props.start,
      end: props.start + props.height,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
          unit: "",
        },
      ],
    },
  ];

  let options = {
    threshold: .7
  }

  React.useEffect(() => {
    if (sectionRef.current&&!observer) {
      observer = new IntersectionObserver(([entry]) => {
        if(entry.isIntersecting) {
          if(!playing){
            videoRef.current.play();
            setPlaying(true);
          }
        }else{
          videoRef.current.pause();
        }
      },options);
      observer.observe(sectionRef.current);
    }
    props.total((sectionRef.current?sectionRef.current.clientHeight:0));
    return () => {};
  });

  return (
    <div ref={sectionRef} id={props.id} className={`metaverse-section`}>
      {(props.property=='left'&&props.orientation!='portrait')&&<div className="metaverse-container">
        <Plx
          className={`metaverse-copy metaverse-copy-${props.property}`}
          animateWhenNotInViewport={true}
          parallaxData={parallaxData}
          dangerouslySetInnerHTML={{__html: props.copy}}
        />
      </div>}
      <div className="metaverse-video">
        <video
          playsInline
          loop
          muted={true}
          ref={videoRef}
        >
          <source src={props.videoSrc} type="video/mp4" />
        </video>
      </div>
      {(props.property=='right'||props.orientation=='portrait')&&<div className="metaverse-container">
        <Plx
          className={`metaverse-copy metaverse-copy-${props.property}`}
          animateWhenNotInViewport={true}
          parallaxData={parallaxData}
          dangerouslySetInnerHTML={{__html: props.copy}}
        />
      </div>}
    </div>
  );
};

export default MetaverseSection;
